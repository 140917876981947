/** @jsx jsx */
import { jsx } from 'theme-ui'
import { graphql, Link } from 'gatsby'
import { Box, Flex } from 'rebass'
import { MdChevronRight, MdInfoOutline, MdWarning, MdBlock } from "react-icons/md"

import { Container, Layout, Section } from '@giraldomac/gatsby-theme-mmdbase/src/components/layout'
import { SEO } from '@giraldomac/gatsby-theme-mmdbase/src/components/elements'
import { PubHero } from '../../components/elements'

const Index = ({ data }) => {

  const page = data.prismic.notices

  return (
    <Layout>
      <SEO meta_title={page.meta_title} meta_description={page.meta_description} />
      <PubHero title="Notices" />
      <Section >
        <Container>
          <Flex flexWrap="wrap" justifyContent="center">

            {/* General */}
            <Box width={['full', 4 / 5, 3 / 4]} color="white">
              <Box sx={{ bg: 'blue.5', p: 4, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h2 sx={{ margin: 0 }}>General Notices</h2><MdInfoOutline sx={{ fill: 'blue.6', fontSize: '3em' }} />
              </Box>
              <Box sx={{
                bg: 'blue.6', p: 4
              }}>
                <p sx={{ marginBottom: 0 }}>PUB strives to increase public awareness. It takes a community effort to ensure the safety of ourselves and our land. You can find general notices about preserving your utilities due to weather or other conditions here.</p>
                <Link to="notices/general" sx={{ color: 'white', textDecoration: 'none', display: 'block', mt: 2, display: 'flex', alignItems: 'center', fontWeight: 'bold', "&:hover": { color: 'white', textDecoration: 'underline' } }}>Learn More <MdChevronRight /></Link>
              </Box>
            </Box>

            {/* Emergencies */}
            <Box width={['full', 4 / 5, 3 / 4]} color="white">
              <Box sx={{ bg: 'red.5', p: 4, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h2 sx={{ margin: 0 }}>Emergencies</h2><MdWarning sx={{ fill: 'red.6', fontSize: '3em' }} />
              </Box>
              <Box sx={{
                bg: 'red.6', p: 4
              }}>
                <p sx={{ marginBottom: 0 }}>In the case of an emergency, PUB wants our customers to be the first to know. We will help you prepare for, and prevent utility mishaps that can lead to emergencies or problems. Check here for the latest.</p>
                <Link to="notices/emergencies" sx={{ color: 'white', textDecoration: 'none', display: 'block', mt: 2, display: 'flex', alignItems: 'center', fontWeight: 'bold', "&:hover": { color: 'white', textDecoration: 'underline' } }}>Learn More <MdChevronRight /></Link>
              </Box>
            </Box>

            {/* Service Interruptions */}
            <Box width={['full', 4 / 5, 3 / 4]} color="white">
              <Box sx={{ bg: 'orange.5', p: 4, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h2 sx={{ margin: 0 }}>Service Interruptions</h2><MdBlock sx={{ fill: 'orange.6', fontSize: '3em' }} />
              </Box>
              <Box sx={{
                bg: 'orange.6', p: 4
              }}>
                <p sx={{ marginBottom: 0 }}>No matter the reason for service interruptions, PUB strives to inform and educate its customers on what is occurring and why. For reported service interruptions in your area, find the latest here.</p>
                <Link to="notices/interruptions" sx={{ color: 'white', textDecoration: 'none', display: 'block', mt: 2, display: 'flex', alignItems: 'center', fontWeight: 'bold', "&:hover": { color: 'white', textDecoration: 'underline' } }}>Learn More <MdChevronRight /></Link>
              </Box>
            </Box>

          </Flex>
        </Container>
      </Section>
    </Layout>
  )
}

export default Index

export const query = graphql`
query NoticesQuery {
  prismic {
    notices(lang: "en-us", uid: "notices") {
      title
      content
      meta_title
      meta_description
    }
  }
}
`